import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import {
  SECTIONS,
  SIDE_BY_SIDE_LAYOUT_IMAGE_LEFT,
  SIDE_BY_SIDE_LAYOUT_IMAGE_RIGHT,
  SIDE_BY_SIDE_LAYOUT_IMAGE_NONE,
  getPostCover,
  isExperimentEnabled,
  resolveId,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_USE_RESOLVED_COVER_IMAGE, EXPERIMENT_POST_CARD_ALIGNMENT } from '@wix/communities-blog-experiments';

import { connect } from '../../../../common/components/runtime-context';
import PostListItemHeader from '../../../../common/components/post-list-item-header';
import PostTitle from '../../../../common/components/post-title';
import PostListItemCover from '../../../../common/components/post-list-item-cover';
import Link from '../../../../common/components/link/internal-link';
import { getLineCounts, getFeedColorClassName } from '../../../../common/services/layout-config';
import { HorizontalSeparatorForPostCard } from '../../../../common/components/separator';
import withFeedBorderWidth from '../../../../common/hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../../../common/hoc/with-feed-metadata-settings';
import withFontClassName from '../../../../common/hoc/with-font-class-name';
import withPostFontSize from '../../../../common/hoc/with-post-font-size';
import withPermissions from '../../../../common/hoc/with-permissions';
import withAuth from '../../../../common/hoc/with-auth';
import { getSection } from '../../../../common/selectors/section-selectors';
import { getIsMemberAreaInstalled } from '../../../../common/store/communities-context/communities-context-selectors';
import { getPostActions } from '../../../../common/services/post-actions';
import LikeButtonWithCount from '../../../../common/components/like-button-with-count';
import CommentCountCompact from '../../../../comments/components/comment-count-compact';
import ViewCountCompact from '../../../../common/components/view-count-compact';
import PostHeaderIcons from '../../../../common/components/post-header-icons';
import { MoreButton } from '../../../../common/components/more-button';
import { importPostActions } from '../../../../common/components/post-actions';

import { ThreeDotsIcon } from '../../../../common/components/icons/three-dots-icon';
import withLayoutColorClasses from '../../../../common/hoc/with-layout-color-classes';
import withIsFeedDesignEnabled from '../../../../common/hoc/with-is-feed-design-enabled';
import {
  getSBSLayoutImagePosition,
  getIsMoreButtonEnabled,
  getMobileContentAlignment,
} from '../../../../common/selectors/app-settings-selectors';
import { getContentAlignmentStyles } from '../../../../common/services/content-alignment-helpers';
import { getViewCount, getCommentCount } from '../../../../common/store/post-counters/post-counters-selectors';
import styles from './side-by-side.scss';
import withMediaHosts from '../../../../common/hoc/with-media-hosts';
import PostListItemCategoryLabel from '../../../../common/components/post-list-item-category-label';
import { getIsRTL } from '../../../../common/store/basic-params/basic-params-selectors';
import alignmentStyles from '../../../../common/styles/post-list-item-alignment.scss';

export const PostListItemSideBySide = ({
  borderWidth,
  contentFontClassName,
  forPublicUser,
  getPostClassName,
  iconColorClassName,
  itemConfig,
  onLikeClick,
  post,
  postMetadataFontSize,
  postTitleFontSize,
  showCommentCount,
  showLikeCount,
  showViewCount,
  sideBySideLayoutImagePosition,
  titleFontClassName,
  type,
  showMoreOptionsMenu,
  isMoreButtonEnabled,
  showAuthorName,
  showReadingTime,
  showPublishDate,
  viewCount,
  imageHost,
  videoHost,
  totalComments,
  can,
  showCategoryLabel,
  alignment,
  isRTL,
  useResolvedCoverImage,
}) => {
  const { shouldRender: withCover } = getPostCover(post, imageHost, videoHost, useResolvedCoverImage);
  const lineCounts = getLineCounts(itemConfig, withCover);
  const postLink = `/${post.slug}`;

  const containerClassName = classNames(
    styles.container,
    alignmentStyles[alignment],
    contentFontClassName,
    'blog-text-color',
    'blog-card-background-color',
    'blog-card-border-color',
    'post-list-item',
    getPostClassName('border-color', 'post-container', getFeedColorClassName(type, 'background-color')),
  );

  const contentWrapperClassName = classNames(
    styles.flexContainer,
    sideBySideLayoutImagePosition === SIDE_BY_SIDE_LAYOUT_IMAGE_LEFT && styles.pullImageLeft,
    isRTL && alignmentStyles.isRTL,
  );

  const linkClassName = classNames(styles.textWrapper, post.isPinned && styles.withIcons);
  const headerClassName = classNames(styles.header, contentFontClassName, alignmentStyles.headerContainer);

  const titleStyle = { fontSize: postTitleFontSize };
  const showCommentStats = showCommentCount && (totalComments > 0 || !post.isCommentsDisabled);
  const moreButtonId = `more-button-${resolveId(post)}`;

  const footerClassName = classNames(
    getPostClassName('description-font', getFeedColorClassName(type, 'description-color')),
    styles.footer,
  );

  const countersVisible = showLikeCount || showCommentStats || showViewCount;
  const isImagePositionNone = sideBySideLayoutImagePosition === SIDE_BY_SIDE_LAYOUT_IMAGE_NONE;
  const showFooter = isMoreButtonEnabled || countersVisible || !isImagePositionNone;
  const showMoreButtonInHeading = isMoreButtonEnabled && !countersVisible && isImagePositionNone;

  const showPostCover =
    getPostCover(post, imageHost, videoHost, useResolvedCoverImage).shouldRender && !isImagePositionNone;

  const coverWrapperClassName = classNames(
    styles.imageContainer,
    sideBySideLayoutImagePosition === SIDE_BY_SIDE_LAYOUT_IMAGE_LEFT && styles.rightMargin,
  );

  const moreButtonIcon = (
    <ThreeDotsIcon className={classNames(iconColorClassName, 'blog-hover-container-element-fill')} />
  );

  const isMoreButtonInHeadingEnabled =
    showMoreOptionsMenu && showMoreButtonInHeading && !showFooter && isMoreButtonEnabled;
  const showHeader = isMoreButtonInHeadingEnabled || showAuthorName || showReadingTime || showPublishDate;
  const separatorClass = showFooter ? styles.separator : classNames(styles.separator, styles.withoutFooter);

  return (
    <article className={containerClassName} tabIndex="0" style={{ borderWidth }} data-hook="post-list-item">
      <div className={contentWrapperClassName}>
        <div className={classNames(styles.titleContainer, alignmentStyles.textAlign)}>
          <Link to={postLink} className={linkClassName} addHoverClasses={false}>
            <PostTitle
              type={type}
              title={post.title}
              lineCount={lineCounts.title}
              style={titleStyle}
              className={classNames(styles.title, titleFontClassName, showCategoryLabel && styles.withCategoryLabel)}
            />
          </Link>
          {showCategoryLabel && (
            <PostListItemCategoryLabel className={alignmentStyles.categoryLabel} post={post} postListLayout={type} />
          )}
          {showHeader && (
            <PostListItemHeader
              className={headerClassName}
              fixedMetadataHeight={false}
              post={post}
              showHeaderIcons={false}
              showMoreButton={isMoreButtonInHeadingEnabled}
              showProfileImage={false}
              style={{ fontSize: postMetadataFontSize }}
              moreButtonIcon={moreButtonIcon}
              moreButtonClass={styles.moreButtonInHeading}
              type={type}
            />
          )}
        </div>
        {showPostCover && (
          <div className={coverWrapperClassName}>
            <PostListItemCover
              post={post}
              postLink={postLink}
              type={type}
              canPlayVideo
              isPublic={can('share', 'post', post)}
              videoClassName={styles.videoEmbed}
              imageClassName={styles.image}
              isMobile
            />
          </div>
        )}
      </div>
      {showFooter && (
        <div className={footerClassName}>
          {countersVisible && (
            <div className={styles.socialButtons} style={{ fontSize: postMetadataFontSize }}>
              {showLikeCount && (
                <div className={styles.socialButton}>
                  <LikeButtonWithCount
                    onClick={forPublicUser(() => onLikeClick(resolveId(post)))}
                    entity={post}
                    className="post-footer__like-button"
                    showZero
                    switchCounterAndButton
                  />
                </div>
              )}
              {showViewCount && (
                <div className={classNames('description-font', styles.socialButton)}>
                  <ViewCountCompact count={viewCount} tabIndex={0} />
                </div>
              )}
              {showCommentStats && (
                <div className={classNames('description-font', styles.socialButton)}>
                  <CommentCountCompact
                    displayIcons={itemConfig.displayFooterIcons}
                    count={totalComments}
                    showZero
                    tabIndex={0}
                  />
                </div>
              )}
            </div>
          )}
          <PostHeaderIcons post={post} className={styles.icons} iconClassName="blog-button-fill" />
          {isMoreButtonEnabled && (
            <div className="blog-more-icon-fill">
              <MoreButton className={styles.moreButton} id={moreButtonId} icon={moreButtonIcon}>
                {async () => {
                  const PostActions = await importPostActions();
                  return <PostActions post={post} focusOnCloseId={moreButtonId} />;
                }}
              </MoreButton>
            </div>
          )}
        </div>
      )}
      <HorizontalSeparatorForPostCard className={separatorClass} />
    </article>
  );
};

PostListItemSideBySide.propTypes = {
  borderWidth: PropTypes.number,
  contentFontClassName: PropTypes.string.isRequired,
  contentFontClassNameWithStyle: PropTypes.string.isRequired,
  forPublicUser: PropTypes.func,
  getPostClassName: PropTypes.func.isRequired,
  iconColorClassName: PropTypes.string.isRequired,
  itemConfig: PropTypes.object.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  post: PropTypes.object,
  postDescriptionFontSize: PropTypes.number.isRequired,
  postMetadataFontSize: PropTypes.number.isRequired,
  postTitleFontSize: PropTypes.number.isRequired,
  section: PropTypes.oneOf(SECTIONS),
  showCommentCount: PropTypes.bool,
  showCommentStats: PropTypes.bool,
  showLikeCount: PropTypes.bool,
  showMoreButton: PropTypes.bool,
  showPostDescription: PropTypes.bool,
  showViewCount: PropTypes.bool,
  sideBySideLayoutImagePosition: PropTypes.oneOf([
    SIDE_BY_SIDE_LAYOUT_IMAGE_LEFT,
    SIDE_BY_SIDE_LAYOUT_IMAGE_RIGHT,
    SIDE_BY_SIDE_LAYOUT_IMAGE_NONE,
  ]),
  titleFontClassName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  imageHost: PropTypes.string.isRequired,
  videoHost: PropTypes.string.isRequired,
  showMoreOptionsMenu: PropTypes.bool,
  isMoreButtonEnabled: PropTypes.bool,
  showAuthorName: PropTypes.bool,
  showReadingTime: PropTypes.bool,
  showPublishDate: PropTypes.bool,
  viewCount: PropTypes.number.isRequired,
  totalComments: PropTypes.number.isRequired,
  showCategoryLabel: PropTypes.bool,
  alignment: PropTypes.string,
  useResolvedCoverImage: PropTypes.bool,
};

PostListItemSideBySide.defaultProps = {
  showViewCount: true,
  showCommentCount: true,
  showLikeCount: true,
};

const mapRuntimeToProps = (state, { post, canSee }) => {
  const isRTL = getIsRTL(state);
  const contentAlignmentMobile = getMobileContentAlignment(state, isRTL);
  const postActions = getPostActions({
    post,
    canSee,
    enableShare: true,
    enableSubscribe: getIsMemberAreaInstalled(state),
  });
  const section = getSection(state);
  const showMoreButton = Boolean(postActions.length);
  const isContentAlignmentEnabled = isExperimentEnabled(state, EXPERIMENT_POST_CARD_ALIGNMENT);
  const postId = resolveId(post);

  return {
    showMoreButton,
    section,
    sideBySideLayoutImagePosition: getSBSLayoutImagePosition(state, section),
    isMoreButtonEnabled: getIsMoreButtonEnabled(state, showMoreButton),
    viewCount: getViewCount(state, postId),
    totalComments: getCommentCount(state, postId),
    alignment: isContentAlignmentEnabled ? getContentAlignmentStyles(contentAlignmentMobile, isRTL) : undefined,
    useResolvedCoverImage: isExperimentEnabled(state, EXPERIMENT_USE_RESOLVED_COVER_IMAGE),
    isRTL,
  };
};

export default flowRight(
  withLayoutColorClasses,
  withPermissions,
  withFontClassName,
  withPostFontSize,
  withFeedMetadataSettings,
  withFeedBorderWidth,
  withIsFeedDesignEnabled,
  withAuth,
  withMediaHosts,
  connect(mapRuntimeToProps),
)(PostListItemSideBySide);
